@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.text1 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.text2 {
  font-family: "Syne", sans-serif;
}

.background-animate {
  background-size: 400%;

  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
}

@keyframes AnimationName {
  0%,
  100% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
}

.boxShadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

/* Scoll Bar  */
::-webkit-scrollbar {
  width: 0.4em;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: #d3d3d3;
  border-radius: 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #307358;
  border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #307358;
}

::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls {
  display: none !important;
}

video::-webkit-media-controls-enclosure {
  display: none !important;
}

.custom-video-controls {
  z-index: 2147483647;
}

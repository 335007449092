.progress-section {
    width: fit-content;
    margin: 0px 0;
    display: flex;
    flex-direction: column;
    justify-items: start;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .progress-container {
    position: relative;
    width: 100%;
    margin: 0 auto;
    background-color: #e0e0e0; /* Light gray background */
    border-radius: 15px;
    overflow: hidden;
    height: 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    animation: pulse 2s infinite;
    box-sizing: border-box;
  }
  
  .progress-bar {
    height: 100%;
    background: linear-gradient(90deg, #28a745, #66ff99);
    line-height: 40px;
    color: black;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    border-radius: 15px 0 0 15px;
    box-sizing: border-box; /* Ensure padding and borders are included in width */

    position: relative;
  }
  
  .pd{
    transition: width 2s ease-out; 
  }
  .progress-bar.animate {
    animation: load 2s ease-out forwards;
  }
  
  @keyframes load {
    0% {
      width: 0;
    }
    100% {
      width: 100%; /* Full width based on percentage */
    }
  }
  
  .progress-bar::after {
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    bottom: 0;
    width: 10px;
    background: inherit;
    filter: brightness(0.9);
    transform: skewX(-45deg);
  }
  
  .raised-amount {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
  }

  .market-raised{
    font-size: 12px;
  }
  
  .progress-line {
    position: absolute;
    bottom: -10px;
    width: 1px;
    height: 40px; /* Adjust the height to match your needs */
    background: #333;
  }
  
  .softcap-line {
    left: 50%;
    transform: translateX(-50%);
  }
  
  .hardcap-line {
    right: 5%; /* Move the line slightly to the left */
  }
  
  .marker-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto;
    margin-top: 20px;
    position: relative;
  }
  
  .marker {
    font-size: 14px;
    color: #333;
    background: #fff;
    padding: 2px 5px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
  }
  
  .raised-amount-marker {
    position: absolute;
    left: 8.26%;
    transform: translateX(-50%);
    font-weight: bold;
  }
  
  @keyframes pulse {
    0% {
      box-shadow: 0 0 20px rgba(0, 255, 150, 0.5);
    }
    100% {
      box-shadow: 0 0 40px rgba(0, 255, 150, 0.9);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .progress-container {
      width: 90%;
    }
  
    .marker-container {
      width: 90%;
    }
  
    .marker {
      font-size: 12px;
    }

    .market-raised{
      font-size:8px;
    }
    .raised-amount{
      font-size:8px;
    }
  }
  